<template>
  <div class="overall">
    <div class="content">
      <!-- 检索条件 -->
      <el-row>
        <el-col>
          <el-button type="primary" @click="updateVisible = true;dialogTitle = '新增';">新增标签</el-button>
        </el-col>
      </el-row>
<!-- table -->
      <div class="tableBox">
            <el-table
                :data="tableData"
                v-loading="tableLoading"
                max-height="650"
                border
                style="width: 100%">
                <el-table-column
                    prop="biaoqian" 
                    label="标签名称"
                    width="200">
                </el-table-column>
                <el-table-column
                    prop="biaoqian" 
                    label="标签内容">
                </el-table-column>
                
                <el-table-column
                    label="操作"
                    width="200">
                    <template slot-scope="scope">
                        <el-button
                        @click.native.prevent="handleEdit(scope.$index, scope.row)"
                        type="primary">
                        编辑
                        </el-button>
                        <el-button
                        @click.native.prevent="handleDelete(scope.$index, scope.row)"
                        type="danger">
                        删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
<!-- 分页 -->
        <div class="paginationBox">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            background
            :total="total">
          </el-pagination>
        </div>
<!-- 新增以及编辑弹框 -->
        <el-dialog
            :title="dialogTitle"
            :visible.sync="updateVisible"
            :before-close="beforeClose"
            width="30%">
            <el-form 
                ref="form"
                :model="form"
                :rules="rules"
                label-width="90px">
                <el-form-item label="标签内容" prop="biaoqian">
                    <el-input placeholder="请输入标签内容" v-model.trim="form.biaoqian"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="beforeClose()">取 消</el-button>
                <el-button type="primary" @click="handleConfirm()">确 定</el-button>
            </span>
        </el-dialog>
<!-- 删除弹窗 -->
        <!-- <el-dialog
            title="删除"
            :visible.sync="delVisible"
            :before-close="beforeClose"
            width="30%">
            <span>是否删除这条标签内容?</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="delVisible = false; itemId = -1">取 消</el-button>
                <el-button type="primary" @click="delVisible = false; detailLabelDel(itemId)">确 定</el-button>
            </span>
        </el-dialog> -->

    </div>
  </div>
</template>

<script>
import {getList,remove,update} from '../../api/detailLabel';
export default {
  data(){
    return{
      //页面属性初始化赋值
      itemId: "",
      currentPage: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      tableLoading: true,
      updateVisible: false,
      delVisible: false,
      dialogTitle: '',
      // from属性初始化
      form:{
        biaoqian:"",
      },
      // 前台验证
      rules:{
        biaoqian: [
                    { required: true, message: '标签内容', trigger: 'blur' },
                ],
      },
    }
  },
  // 1.页面初始化方法
  mounted(){
    this.detailLabelList();
  },
  //2.监听方法
  methods:{
        //列表接口
        // async 声明了一个 异步函数，这个异步函数体内有一行 await 语句，它告示了该行为同步执行。
        async detailLabelList(){
          try {
            //封装参数
            const params = {
              page: this.currentPage,
              limit:this.pageSize
            }
            // 显示加载中动画
            this.tableLoading = true;
            // 访问后台接口
            let data= await getList(params);
            //数据总条数
            this.total = data.total;
            // 表格数据
            this.tableData = data.pageSize;
          } catch (error) {
            console.log("error",error);
          }
          // 查询完毕取消加载中动画
          this.tableLoading = false;
        },

        //新增/编辑接口
        async detailLabelUpdate(){
          try {
            const params={
              ...this.form
            }
           
            if (this.dialogTitle ==="编辑") {
              params.id = this.itemId
            }
            await update(params);
            this.$message({
                      showClose: true,
                      type: "success",
                      message: "操作成功!"
                });
            this.detailLabelList();
          } catch (error) {
            console.log("error",error);
          }
          this.updateVisible = false;

        },

        
        //更改一页的条数
        handleSizeChange(size) {
            this.pageSize = size;
            //重新走页面初始化方法
            this.detailLabelList();
        },
        //更改页数
        handleCurrentChange(current) {
            this.currentPage = current;
            //重新走页面初始化方法
            this.detailLabelList();
        },
        //新增/编辑页弹出框取消
        beforeClose() {
            this.updateVisible = false;
            //表单数据置空，并移除校验
            this.$refs['form'].resetFields();
        },
        //新增按钮
        handleConfirm(){
          this.$refs['form'].validate((valid) => {
            if(valid){
              this.detailLabelUpdate();
            }else{
              console.log("error submit!")
              return false;
            }
          });
        },

        // 编辑按钮
        handleEdit(index,rows){
          this.dialogTitle ="编辑";
          this.updateVisible =true;
          // 将选中这条数据的值赋值给编辑弹窗
          this.form = rows;
          this.itemId = rows.id;
        },

        // 删除按钮
        // handleDelete(index,rows){
        //   this.delVisible = true;
        //   this.itemId= rows.id;
        // }
        //删除接口
        // async detailLabelDel(id){
        //   try {
        //     const params ={
        //       id
        //     }
        //     this.tableLoading= true
        //     await remove(params);
        //     this.detailLabelList();
        //   } catch (error) {
        //     console.log("error",error)
        //   }
        //   this.tableLoading= false

        // },
        //删除
        handleDelete(index,row) {
            let id = row.id
           this.$confirm("此操作将永久删除该数据, 是否继续?", {
               confirmButtonText: "确定",
               cancelButtonText: "取消",
               type: "warning"
           }).then(() => {
               const params = {
                id
               };
               return remove(params);
           }).then(() => {
               this.detailLabelList();
               this.$message({
                    showClose: true,
                    type: "success",
                    message: "操作成功!"
               });
            }).catch(() => {
              this.$message({
              type: 'info',
              message: '已取消删除'
              });          
            });
       },

  }

}
</script>

<style lang="scss" scoped>
.overall {
    .content{
        .tableBox {
            padding-top: 20px;
        }
        .paginationBox {
            padding-top: 20px;
            text-align: center;
        }
    }
}

</style>