import http from "../utils/http";
import { stringify } from 'qs';

export function getList(params) {
    return http.get("/zJob/jobsList?" + stringify(params));
}

export function remove(params) {
    return http.get("/zJob/update?" + stringify(params));
}

export function update(params) {
    return http.post("/zJob/add?" + stringify(params));
}
